@import "../../common/properties.css";

.experience-info {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  font-size: 1.3rem;
}

.experience-content {
  margin: 3px 15px;
  text-align: left;
}
