@import "../../common/properties.css";

.slide-practice-background {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  box-sizing: border-box;
  background-image: url("../../../assets/image/png/slide_blue.png");
  background-repeat: no-repeat;
  background-position: cover;
  background-size: 100% 100%;
  max-width: 720px;
  max-height: 405px;
  margin: 0;

  /* padding: 0; */
}

/* 背景 */
.bg-blue {
  background-image: url("../../../assets/image/png/slide_blue.png");
}

.bg-yellow {
  background-image: url("../../../assets/image/png/slide_yellow.png");
}

.bg-red {
  background-image: url("../../../assets/image/png/slide_red.png");
}
