@import "../../common/properties.css";

.button {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  display: inline-block;
  padding: 0.4em 1em;
  color: #555;
  text-align: center;
  font-size: 1.5rem;

  /* line-height: 3; */

  text-decoration: none;
  white-space: nowrap;
  background-color: #FFF;
  border-radius: 4px;
  border: 1px solid #BBB;
  cursor: pointer;
  box-sizing: border-box;
}

.button:hover,
.button:focus {
  opacity: 0.8;
}

/* プライマリーボタン */
.primary {
  color: #FFF;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

/* アラートボタン */
.alert {
  color: #FFF;
  background-color: var(--color-red);
  border-color: var(--color-red);
}

/* クリックする機能だけのボタン(他のDOMを囲むだけ)JustClickButton */
.just-click {
  margin: 0;

  /* padding: 0.8rem 0; */
  padding: 0;
  border: none;
  color: transparent;
  background-color: transparent;
}

/* アイコン付きボタン */
.button img {
  vertical-align: sub;
  margin-right: 1rem;
}

/* 詳しくはボタン moreButton */
.more-btn {
  display: inline-block;
  text-align: center;
  width: 100px;
  color: var(--color-main);
  font-size: 1.2rem;
  font-weight: 500;
  background: #FFF;
  box-shadow: 0 0 6px #0000000F;
  border-radius: 2em;
  padding: 0.3em;
  transition: 0.4s;
}

.more-btn::after {
  content: "";
  background: url("../../../assets/image/svg/icon_trg02.svg") center center no-repeat;
  width: 1em;

  /* padding: 1em; */
  vertical-align: middle;
  padding: 0.3em;
  margin-left: 0.5em;
  background-size: contain;
  position: relative;
  left: 0;
  transition: 0.3s;
}

.more-btn:hover,
.more-btn:focus {
  opacity: 1;
}

.more-btn:hover::after {
  left: 3px;
}

/* 灰色ボタン */
.gray {
  color: #FFF;

  /* font-weight: bold; */

  background-color: var(--color-light-gray);
  border-color: var(--color-light-gray);
}

.gray:hover,
.gray:focus {
  opacity: 1;
  cursor: not-allowed;
}

/* ボタンをラップするdiv */
.button-wrap {
  text-align: center;
}

.button-wrap:not(:first-child) {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
