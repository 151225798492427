@import "../../common/properties.css";

.header-presenter {
  box-sizing: border-box;
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  padding: 15px;
  position: relative;
  border-top: 5px solid var(--color-main);

  /* height: 100px; */
  border-bottom: 1px solid #CCC;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFF;
}

.header-logo {
  width: 160px;
  margin: 0;
  line-height: 1;
}

ul {
  box-sizing: border-box;
  padding-left: 0;
  margin-top: 0;
  margin-block-end: 0;
  list-style: none;
}

/* Larger than mobile */
@media (min-width: 400px) {}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {}

/* Larger than tablet */
@media (min-width: 750px) {
  .header-presenter {
    padding: 15px 40px;
  }

  /* .header-logo {} */
}

/* Larger than desktop */
@media (min-width: 1000px) {}

/* Larger than Desktop HD */
@media (min-width: 1200px) {}

/*  gnav
------------------------------------ */
.gnav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.gnav.active {
  display: block;
  animation: fade-in 0.5s ease 0s 1 normal;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.gnav-list {
  box-sizing: border-box;
  margin: 0;
  background-color: var(--color-main);
  padding-top: 70px;
  overflow: auto;
}

.gnav-item {
  box-sizing: border-box;
  margin: 0;
}

.gnav-item a {
  box-sizing: border-box;
  display: block;
  height: 100%;
  color: #FFF;
  padding: 1em 2em;
  position: relative;
  border-bottom: 1px solid var(--color-pale);
}

.gnav .gnav-item a:hover {
  opacity: 0.6;
}

.gnav-item > .pc-just-click-button > a {
  border-bottom: none;
  display: flex;
  align-items: center;
}

.gnav-item.forum a .badge {
  display: none;
}

/* .gnav-item.profile {} */

.gnav-item.profile > a {
  background: url("../../../assets/image/svg/icon_trg.svg") no-repeat right center;
  border-bottom: none;
  display: flex;
  align-items: center;
}

.gnav-item.profile .profile-photo {
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 1rem;
}

.gnav-sub-list {
  box-sizing: border-box;
  padding: 1em 0;
  margin: 0;
  border-top: 1px dotted var(--color-pale);
}

.gnav-sub-item {
  box-sizing: border-box;
  margin: 0;
}

.gnav-sub-item a {
  box-sizing: border-box;
  font-size: 1.4rem;
  white-space: nowrap;
  border-bottom: none;
}

.gnav-sub-item a::before {
  box-sizing: border-box;
  content: "-";
  padding-right: 1em;
}

/* 非ログイン時メニュー */

.gnav-list-no-login {
  margin: 0;
  background-color: var(--color-main);
  padding: 71px 0 30px;
}

.gnav-list-no-login .gnav-item {
  margin: 20px auto 0;
  width: 90%;
  padding: 0.5em;
}

.gnav-list-no-login .gnav-item a {
  text-align: center;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  border-bottom: none;
  font-size: 1.4rem;
  color: #FFF;
  padding: 10px 5px;
}

.gnav-list-no-login .gnav-item a:hover {
  opacity: 0.6;
}

.gnav-list-no-login .gnav-item.login a {
  color: var(--color-main);
  background-color: #FFF;
  border: 1px solid #999;
  border-radius: 3px;
  margin-top: 40px;
}

.gnav-list-no-login .gnav-item.register a {
  color: #FFF;
  background-color: var(--color-sub);
  border-radius: 3px;
}

/* スマホ用ハンバーガーメニュー */

.sp-nav-logo {
  box-sizing: border-box;
  padding: 15px;
  position: fixed;
  border-top: 5px solid var(--color-main);
  border-bottom: 1px solid #CCC;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFF;
  width: 100%;
  z-index: 1;
}

.nav-btn {
  box-sizing: border-box;
  display: block;
  text-align: center;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 10px;
  left: auto;
  right: 10px;
  z-index: 20;
  cursor: pointer;
}

.nav-btn span {
  box-sizing: border-box;
  transition: 0.5s;
  display: block;
  width: 40px;
  border-bottom: 3px solid #086597;
  position: absolute;
  left: 5px;
}

.nav-btn span:nth-child(1) {
  top: 14px;
}

.nav-btn span:nth-child(2) {
  top: 25px;
}

.nav-btn span:nth-child(3) {
  top: 37px;
}

/* タップした後のXボタン */

.nav-btn.active span:nth-child(1) {
  top: 26px;
  left: 5px;
  border-bottom: 3px solid var(--color-main);

  /* マイナス45度回転 */
  transform: rotate(-45deg);
}

.nav-btn.active span:nth-child(2),
.nav-btn.active span:nth-child(3) {
  top: 26px;
  border-bottom: 3px solid var(--color-main);

  /* 45度回転 */
  transform: rotate(45deg);
}

/* Larger than mobile */
@media (min-width: 400px) {}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {}

/* Larger than tablet */
@media (min-width: 750px) {
  .gnav {
    display: block;
    box-sizing: border-box;
    position: static;
    width: auto;
    height: auto;
    background: transparent;
    overflow: visible;
  }

  .gnav.active {
    animation: unset;
  }

  .sp-nav-logo {
    display: none;
  }

  .gnav-list {
    box-sizing: border-box;
    display: flex;
    margin: 0;
    padding: 0;
    background-color: transparent;
    overflow: visible;
  }

  .gnav-item {
    margin: 0;
  }

  .gnav-item > .mb-just-click-button {
    display: inline-block;
    padding: 0.1em 1em;
    text-align: center;
  }

  .gnav-item a {
    box-sizing: border-box;
    display: block;
    height: 100%;
    color: #999;
    padding: 30px 5px 0;
    font-size: 1.2rem;
    position: relative;
    border-bottom: none;
  }

  .gnav-item a::before,
  .gnav-item a::after {
    content: "";
    display: block;
    width: 2.4rem;
    height: 100%;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s;
  }

  .gnav-item.course a::before {
    background-image: url("../../../assets/image/svg/icon_course.svg");
  }

  .gnav-item.course a::after {
    background-image: url("../../../assets/image/svg/icon_course_on.svg");
    opacity: 0;
  }

  .gnav-item.training a::before,
  .gnav-item.training a::after {
    width: 2.6rem;
  }

  .gnav-item.course a:hover::after,
  .gnav-item.training a:hover::after,
  .gnav-item.forum a:hover::after {
    opacity: 1;
  }

  .gnav-item.training a::before {
    background-image: url("../../../assets/image/svg/icon_training.svg");
  }

  .gnav-item.training a::after {
    background-image: url("../../../assets/image/svg/icon_training_on.svg");
    opacity: 0;
  }

  /* .gnav-item.forum a::before {
    background-image: url(../images/common/icon_training.svg);
  }

  .gnav-item.forum a::after {
    background-image: url(../images/common/icon_training_on.svg);
    opacity: 0;
  }

  .gnav-item.forum a .badge {
    display: inline;
    position: absolute;
    width: 1.8rem;
    height: 1.8rem;
    font-size: 1.2rem;
    color: #fff;
    right: 25%;
    top: 0;
    background-color: var(--color-accent);
    border-radius: 50%;
    text-align: center;
    line-height: 1.5;
  } */

  .gnav-item a:hover {
    color: var(--color-sub);
  }

  .gnav-item > a {
    box-sizing: border-box;
    padding: 30px 20px 0;

    /* 背景動く対策 */
    transition: none;
  }

  .gnav-item.profile {
    display: flex;
    align-items: center;
    position: relative;
  }

  .gnav-item.profile > .pc-just-click-button > a {
    box-sizing: border-box;
    padding: 0 25px 0 20px;
    color: #333;
    display: flex;
    align-items: center;
    background: url("../../../assets/image/svg/icon_trg.svg") no-repeat right center;
  }

  .gnav-item.profile .profile-photo {
    box-sizing: border-box;
    width: 50px;
    height: 50px;
  }

  .gnav-sub-list {
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 100%;
    transition: 0.3s;
    margin: 0;
    padding: 0;
    z-index: 10;
    background-color: #FFF;
    box-shadow: 0 0 6px #0000000F;
    border-radius: 8px;
  }

  .gnav-sub-item {
    box-sizing: border-box;
    margin: 0;
  }

  .gnav-sub-item a {
    box-sizing: border-box;
    padding: 1em 2em;
    color: #333;
    font-size: 1.4rem;
    white-space: nowrap;
  }

  .gnav-sub-item a::before {
    content: none;
  }

  .gnav-item > .mb-just-click-button,
  .mb-just-click-button,
  .nav-btn {
    box-sizing: border-box;
    display: none;
  }

  /* 非ログイン時メニュー */

  .gnav-list-no-login {
    display: flex;
    margin: 0;
    padding: 0;
    background-color: transparent;
  }

  .gnav-list-no-login .gnav-item {
    margin: 0 0 0 1vw;
    width: auto;
  }

  .gnav-list-no-login .gnav-item a {
    display: block;
    height: 100%;
    position: relative;
    color: #333;
    font-size: 1.3rem;
    align-items: center;
  }

  .gnav-list-no-login .gnav-item a:hover {
    opacity: 0.6;
  }

  .gnav-list-no-login .gnav-item.login a {
    font-size: 1.3rem;
    margin-right: 30px;
    border-radius: 3px;
    border: 1px solid #999;
    padding: 10px 0.8rem;
    margin-top: 0;
  }

  .gnav-list-no-login .gnav-item.register a {
    font-size: 1.3rem;
    color: #FFFF !important;
    background-color: var(--color-sub);
    border-radius: 3px;
    padding: 10px 2em;
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {}

/* Larger than Desktop HD */
@media (min-width: 1200px) {}
