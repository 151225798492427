@import "../../common/properties.css";

.maintenance-template {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
}

.explanation-text {
  background-color: #FFF;
  text-align: center;
}
