@import "../../common/properties.css";

.pdf-slide {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  box-sizing: border-box;
  border: solid 2px black;
  width: 724px;
  height: 409px;
  min-width: 724px;
  min-height: 409px;
}
