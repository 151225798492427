@import "../../common/properties.css";

.textbox {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  padding: 0.8em 1em; /* The 6px vertically centers text on FF, ignored by Webkit */
  background-color: #FFF;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  box-shadow: none;
}
