@charset "utf-8";

.template-properties-local {
  font-size: 1.5rem;
  width: 100%;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.8;
  font-feature-settings: "palt";
  letter-spacing: 0.045em;
  text-align: justify;
  background-color: var(--color-pale);
}
