@import "../../common/properties.css";

.column-area {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  width: 100%;
  float: left;
  box-sizing: border-box;
}

.column-area:first-child {
  margin-left: 0;
}

.side-column {

}

@media (min-width: 1000px) {
  .column-area {
    margin-left: 4%;
  }

  .column-area:first-child {
    margin-left: 0;
  }

  .side-column {
    position: sticky;
    top: 2rem;
  }

  .one-half {
    width: 48%;
  }

  .one-third {
    width: 30.667%;
  }

  .two-third {
    width: 65.3333%;
  }
}
