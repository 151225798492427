@import "../../common/properties.css";

.dom-slide-recall {
  font-family: var(--font-family-regular);
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 20px 40px;
  width: 720px;
  height: 405px;
}

.dom-slide-area {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: center;
  height: 100%;
  width: 100%;

  /* flex親要素としての指定 */
  display: flex;
  align-items: center;
}

.dom-slide-content {
  box-sizing: border-box;
  height: fit-content;
}

.dom-slide-title {
  box-sizing: border-box;
  font-size: 2.5rem;

  /* margin-bottom: 10px; */
  margin-bottom: 25px;
}

.dom-slide-answerarea {
  /* flex親要素としての指定 */
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  align-content: space-around;
}

.dom-slide-answerarea.indivisible-columns {
  justify-content: flex-start;
}

.input-group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
}

span.input-item,
input[type="text"].input-item {
  box-sizing: border-box;
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  background-color: #FFF;
  border: none;
  border-radius: 6px;
  padding: 0.5em 0;
  line-height: 1.6em;
  height: 36.4px;
  margin-bottom: 25px;
}

input[type="text"].input-item {
  border: 1px solid #BBB;
}

span.input-item.correct {
  background-color: var(--color-correct);
}

span.input-item.incorrect {
  background-color: var(--color-incorrect);
}

span.input-item.correct-answer {
  background-color: inherit;
}

.input-group span.input-item,
.input-group input[type="text"].input-item {
  margin-bottom: 5px;
}

.dom-slide-textarea {
  box-sizing: border-box;
  font-size: 1.4rem;
  padding: 10px;
  resize: none;
  width: 100%;
  height: 140px;
}

.three-in-row {
  width: 200px;
}

.four-in-row {
  width: 150px;
}

.four-in-row.indivisible-columns {
  margin-right: 13px;
}

.four-in-row.indivisible-columns:nth-child(4n) {
  margin-right: 0;
}

.five-in-row {
  width: 120px;
}

span.input-item.many-columns,
input[type="text"].input-item.many-columns {
  margin-bottom: 8px;
}

textarea.dom-slide-textarea.many-columns {
  height: 110px;
}

div.button-area {
  margin-top: 20px;
}

div.button-area.many-columns {
  margin: 0;
}
