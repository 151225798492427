@import "../../common/properties.css";

.course-list {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
}

.course-nav ul {
  margin: 0;
}

.course-nav li {
  margin: 0;
  border-top: 1px solid #CED4D8;
}

.course-nav li a {
  display: block;
  padding: 15px 0;
  color: var(--color-main);
  text-decoration: none;
  transition: 0.3s;
}

.course-nav li:first-child {
  border-top: none;
}

.course-nav li:last-child {
  border-bottom: 1px solid #CED4D8;
}

.course-nav .present-course {
  color: var(--color-red);
}

.course-nav .present-course::before {
  content: url("../../../assets/image/svg/icon_caret_right_red.svg");
  margin-left: 1px;
  margin-right: 5px;
}
