@import "../../common/properties.css";

.password-box {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  background-color: #FFF;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
  width: fit-content;
}

.password-box:focus-within {
  outline: auto;
}

.password-box > input {
  padding: 0.8em 1em;
  background-color: #FFF;
  border: none;
  outline: none;
}

.password-box > input:focus {
  border: none;
  outline: none;
}

.password-box > img {
  margin-right: 0.5rem;
}

.password-box > img:hover {
  cursor: pointer;
}
