@import "../../common/properties.css";

.point-modal {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
}

.button-wrap {
  text-align: center;
  margin-top: 20px;
}

.button {
  font-size: 1.3rem;
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgb(255 255 255 / 75%);
  z-index: 100;
}

.modal-header {
  margin: 0;
  padding: 5px 15px 0;
  height: 30px;
  font-size: 1.3rem;
  font-weight: 500;
  color: white;
  background-color: var(--color-main);
}

.modal-content-0pt-area {
  margin: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(90%);
}

.modal-content-area {
  margin: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 40px);
}

.modal-content {
  width: 100%;
  height: fit-content;
}
