@import "../../common/properties.css";

.training-choice-list {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 3rem 0;
  list-style-type: none;
}

.choice-list-item {
  margin: 1.2rem;
}

.choice-list-item input[type="radio"] {
  display: none;
}

.choice-list-item .button {
  display: inline-block;
  text-align: center;
  color: #333;
  border-color: #EEE;
  border-radius: 2rem;
  padding: 0.4em 0.8em;
  font-size: 2rem;
  font-weight: 700;
  transition: 0.3s;
  text-decoration: none;
  white-space: nowrap;
  border: 3px solid #EEE;
  cursor: pointer;
  box-sizing: border-box;
}

.choice-list-item .button:hover,
.choice-list-item input:checked + span {
  border-color: #85D2EA;
  background-color: #E2F4F9;
}

.choice-list-item .disabled-button {
  display: inline-block;
  text-align: center;
  color: #B0B0B0;
  border-color: #EEE;
  border-radius: 2rem;
  padding: 0.4em 0.8em;
  font-size: 2rem;
  font-weight: 700;
  transition: 0.3s;
  text-decoration: none;
  white-space: nowrap;
  border: 3px solid #EEE;
  cursor: pointer;
  box-sizing: border-box;
}

@media (min-width: 1000px) {
  .choice-list-item {
    margin: 1.2rem;
  }

  .choice-list-item .button {
    border-radius: 2.4rem;
    font-size: 2rem;
    font-weight: 700;
  }
}
