@import "../../common/properties.css";

.payment-template {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
}

.explanation-area {
  margin-bottom: 30px;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.payment-table {
  margin: 0 auto;
}
