@import "../../common/properties.css";

.title {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
}

.title-itemized {
  font-size: 1.8rem;
  font-weight: 700;
  padding-bottom: 0.3em;
  margin: 2.5em 0 1.2em;
  border-bottom: 3px solid var(--color-main);
  color: var(--color-main);
  letter-spacing: 0.06em;
  overflow: hidden;
}

.title-itemized:first-child {
  margin-top: 0;
}

/* Larger than tablet */
@media (min-width: 750px) {
  .title-itemized {
    font-size: 2.4rem;
  }
}
