@charset "utf-8";

/* フォント指定 */
@font-face {
  font-family: "Noto Sans JP";
  src: url("../../assets/font/Noto_Sans_JP/NotoSansJP-Regular.otf") format("opentype");
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("../../assets/font/Noto_Sans_JP/NotoSansJP-Medium.otf") format("opentype");
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("../../assets/font/Noto_Sans_JP/NotoSansJP-Bold.otf") format("opentype");
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: "Noto Serif";
  src: url("../../assets/font/Noto_Serif/NotoSerif-Bold.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: "Noto Serif";
  src: url("../../assets/font/Noto_Serif/NotoSerif-Regular.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: Poppins;
  src: url("../../assets/font/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: "Zen Maru Gothic";
  src: url("../../assets/font/Zen_Maru_Gothic/ZenMaruGothic-Black.ttf") format("truetype");
  font-display: swap;
  font-weight: 900;
}

@font-face {
  font-family: Roboto;
  src: url("../../assets/font/Roboto/Roboto-Bold.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
}

:root {
  --font-noto-sans-jp: "Noto Sans JP";
  --font-noto-serif: "Noto Serif";
  --font-poppins: "Poppins";
  --font-zen-maru-gothic: "Zen Maru Gothic";
  --font-roboto: "Roboto";

  /* color setting */
  --color-main: #0071B9;
  --color-sub: #1EAEDB;
  --color-pale: #F5FAFC;
  --color-accent: #D96F2B;
  --color-yellow: #BAA500;
  --color-red: #D96F2B;
  --color-blue: #0071B9;
  --color-light-red: #F8BA93;
  --color-light-green: #D2EAA9;
  --color-light-blue: #56C1CC;
  --color-primary: #1EAEDB;
  --color-gray: #999;
  --color-light-gray: #CCC;
  --color-sub-red: #F55F00;

  /* トレーニング 正解 不正解 */
  --color-correct: #D2EAA9;
  --color-incorrect: #FF8A97;

  /* メッセージバーの色 */
  --color-message-success-bk: #D4E6DF;
  --color-message-error-bk: #FBD7DB;

  /* メッセージバーの文字色 */
  --color-message-success: #0F5132;
  --color-message-error: #842029;

  /* レスポンシブデザイン用のカスタムメディア */

  /* @custom-media --breakpoint-m (min-width: 750px); */
}

/* -------------------------------------------------
  Base Settings
-------------------------------------------------- */

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;  /* =10px */
}

ol,
ul {
  padding-left: 0;
  margin-top: 0;
}

label {
  display: block;
}
