@import "../../common/properties.css";

.slide-practice-histrical-dates {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  border: #000 solid 2px;
  height: 409px;
  width: 724px;
}

.dom-slide {
  display: block;
  margin: 0;
  padding: 20px;
  width: 720px;
  height: 405px;
}

.dom-slide-area {
  margin: 0;
  padding: 0;
  text-align: center;
  height: 100%;
  width: 100%;
}

.dom-slide-title {
  font-size: 2rem;
  margin: 0 0 4px;
}

.dom-slide-content {
  width: 100%;
  height: 350px;
}

.dom-slide-answerarea {
  /* flex親要素としての指定 */
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  height: 280px;
}

div.item-group {
  display: flex;
  align-items: flex-start;
  width: 640px;
  height: auto;
  margin: 0;
}

div.label {
  display: block;
  width: 25px;
  margin-right: 5px;
  text-align: right;
  font-size: 1.6rem;
  font-weight: bold;
}

div.sentence {
  display: block;
  width: calc(100% - 35px);
  font-size: 1.6rem;
  text-align: left;
}

span {
  display: inline;
}

span.keyword {
  font-weight: 700;
  margin: 0 2px;
  color: var(--color-red);
}

span.sentence.result {
  margin: 0 0 0 10px;
  width: 400px;
}

span.input-item,
input[type="text"].input-item {
  display: inline-block;
  width: 100px;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  background-color: #FFF;
  border: none;
  border-radius: 3px;
  margin: 0 2px;
  padding: 3px 0;
  height: calc(2rem + 8px);
}

span.input-item {
  font-size: 1.6rem;
  height: auto;
  padding: 0;
}

input[type="text"].input-item {
  border: 1px solid #BBB;
}

input[type="text"].input-item.answer-width-2,
span.input-item.answer-width-2 {
  width: calc(24px + (1.6rem*2));
}

input[type="text"].input-item.answer-width-3,
span.input-item.answer-width-3 {
  width: calc(24px + (1.6rem*3));
}

input[type="text"].input-item.answer-width-4,
span.input-item.answer-width-4 {
  width: calc(24px + (1.6rem*4));
}

input[type="text"].input-item.answer-width-5,
span.input-item.answer-width-5 {
  width: calc(24px + (1.6rem*5));
}

input[type="text"].input-item.answer-width-6,
span.input-item.answer-width-6 {
  width: calc(24px + (1.6rem*6));
}

span.input-item.correct {
  background-color: var(--color-correct);
}

span.input-item.incorrect {
  /* text-decoration: line-through solid var(--color-message-error); */
  background-color: var(--color-incorrect);
}

span.input-item.result.correct-answer {
  background-color: inherit;
  width: 90px;
}

.button-area {
  margin-top: 0;
}

.button-area.three-in-column {
  margin-top: 15px;
}

.button {
  margin-right: 0;
  width: 280px;
}
