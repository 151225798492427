@import "../../common/properties.css";
@import "../../common/template-properties.module.css";

.withdrawal-template {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
}

.form-area {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  padding: 2rem 7.5% 5rem;
  background-color: #FFF;
  text-align: center;
}

.font-red {
  color: var(--color-accent);
}

.explanation-text {
  margin: 20px 1rem 0;
  text-align: left;
}

.textarea {
  margin: 5px 1rem;
  width: calc(100% - 2rem);
  height: 100px;
  resize: none;
}

.button {
  margin-top: 15px;
  width: 100%;
}

.button-wrap {
  margin-top: 15px;
}

.form-component-wrap {
  text-align: left;
}

@media (min-width: 750px) {
  .d-md-none {
    display: none;
  }

  .form-area {
    max-width: 700px;
  }

  .explanation-text {
    text-align: center;
  }

  .button {
    width: 300px;
  }
}
