@import "../../common/properties.css";

.slide-practice-long-words-table {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  border: #000 solid 2px;
  height: 409px;
  width: 724px;
}

.dom-slide {
  display: block;
  margin: 0;
  padding: 20px 40px;
  width: 720px;
  height: 405px;
}

.dom-slide-area {
  margin: 0;
  padding: 0;
  text-align: center;
  height: 100%;
  width: 100%;
}

.dom-slide-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.dom-slide-title.six-questions {
  margin-top: 20px;
  margin-bottom: 30px;
}

.dom-slide-content {
  width: 100%;
  height: 300px;
}

.dom-slide-answerarea {
  /* flex親要素としての指定 */
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 260px;
}

.dom-slide-answerarea.six-questions {
  height: 180px;
}

.dom-slide-answerarea.six-questions.result {
  height: 240px;
}

div.item-group {
  width: 320px;
  height: 36.4px;
  margin-bottom: 15px;

  /* flex親要素としての指定 */
  display: flex;
  flex-flow: row;
  flex-wrap: no-wrap;
  justify-content: flex-start;
}

div.item-group.result {
  margin-bottom: 0;
}

label.item-label {
  display: block;
  width: 130px;
  font-size: 1.7rem;
  padding-top: 3px;
  margin-right: 5px;
  height: 36.4px;
  font-weight: 600;
}

span.input-item,
input[type="text"].input-item {
  display: block;
  width: 180px;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  background-color: #FFF;
  border: none;
  border-radius: 6px;
  padding: 6px 0;
  line-height: 1.6em;
  height: 36.4px;
}

span.input-item.word-label,
input[type="text"].input-item.word-label {
  width: 190px;
}

input[type="text"].input-item {
  border: 1px solid #BBB;
}

span.input-item.correct {
  background-color: var(--color-correct);
}

span.input-item.incorrect {
  background-color: var(--color-incorrect);
}

span.input-item.result.correct-answer {
  background-color: inherit;
  width: 125px;
}

div.correct-answer {
  height: 1.5rem;
  width: 180px;
  font-size: 1.5rem;
  font-weight: 700;
  margin-left: 135px;
  margin-bottom: 20px;
  background-color: inherit;
}

.button-area {
  margin-top: 5px;
}

.button-area.six-questions {
  margin-top: 15px;
}

.button {
  margin-right: 0;
  width: 280px;
}
