@import "../../common/properties.css";

.ok-cancel-modal {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
}

.modal-header {
  margin: 0;
  padding: 5px 15px 0;
  height: 30px;
  font-size: 1.3rem;
  font-weight: 500;
  color: white;
  background-color: var(--color-red);
}

.modal-content-area {
  margin: 5px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px 8px;
  height: calc(100% - 40px);
}

/* .modal-content {
  width: 100%;
  height: fit-content;
  text-align: center;
} */

.content-text-area {
  text-align: center;
  font-size: 1.4rem;
}

.button-wrap {
  text-align: center;
  margin-top: 20px;
}

.button {
  font-size: 1.3rem;
  margin-left: 5px;
}

.button.ok {
  font-weight: 500;
  color: white;
  background-color: var(--color-red);
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgb(255 255 255 / 75%);
  z-index: 100;
}
