@import "../../common/properties.css";

.contact-template {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
}

.form-item {
  margin-top: 5rem;
}

.form-item-label {
  font-weight: 500;
  margin-bottom: 15px;
}

.form-item input[type="password"],
.form-item input[type="text"],
.form-item input[type="email"] {
  width: 100%;
}

.form-item textarea {
  width: 100%;
  height: 200px;
}

.form-item input:nth-of-type(2) {
  margin: 20px 0;
}

.button {
  margin-top: 10px;
  width: 100%;
}

.post-process {
  text-align: center;
}

.message {
  color: var(--color-red);
}

@media (min-width: 750px) {
  .form-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 0;
  }

  .form-item input,
  .form-item textarea {
    max-width: 400px;
  }

  .align-start {
    align-items: flex-start;
  }

  .form-item-label {
    width: 100%;
    max-width: 248px;
    margin-bottom: 0;
    letter-spacing: 0.05em;
  }

  .form-item-textarea {
    width: 60%;
    max-width: 600px;
    height: 200px;
  }

  .button {
    margin-top: 20px;
    width: 280px;
  }
}
