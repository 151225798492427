@import "../../common/properties.css";

.course-template {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
}

.course-nav ul {
  margin: 0;
}

.course-nav li {
  margin: 0;
}

.course-nav li a {
  display: block;
  padding: 15px 0;
  border-top: 1px solid #CED4D8;
  color: var(--color-main);
  text-decoration: none;
  transition: 0.3s;
}

.course-nav li:last-child {
  border-bottom: 1px solid #CED4D8;
}
