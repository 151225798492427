@import "../../common/properties.css";

.button {
  width: 100%;
}

.form-component-wrap {
  text-align: left;
}

.login-check-area {
  margin-top: 2rem;
}

.form-component-wrap:not(:first-child) {
  margin-top: 2rem;
}

.form-component-wrap input {
  width: 100%;
}

.form-component-wrap .password-box {
  width: 100%;
}

.form-component-wrap .password-box input {
  width: calc(100% - 29px - 1rem);
}

.form-component-wrap label {
  width: 9em;
  flex-shrink: 0;
}

.forget-pw-link {
  text-align: right;
  font-size: 1.3rem;
}

.login-other {
  margin-top: 4rem;
  position: relative;
}

.login-other-txt {
  display: inline-block;
  padding: 0 1em;
  background-color: #FFF;
  position: relative;
}

.login-other::before {
  content: "";
  display: block;
  width: 100%;
  height: 0;
  border-top: 1px solid #EEE;
  position: absolute;
  left: 0;
  top: 0.9em;
}

.login-other-item {
  margin-top: 2rem;
}

.login-other-item img {
  vertical-align: sub;
  margin-right: 1rem;
}

/* Larger than mobile */
@media (min-width: 400px) {}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {}

/* Larger than tablet */
@media (min-width: 750px) {
  .form-component-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button,
  .form-component-wrap input,
  .form-component-wrap .password-box {
    width: 280px;
  }
}
