@charset "UTF-8";

.inline-date-picker .react-datepicker {
  margin-bottom: 55px;
  font-size: 1rem;
  transform-origin: 0 0;
  transform: scale(1.3);
}

.inline-date-picker .react-datepicker__current-month {
  margin-bottom: 5px;
  font-size: 1.3rem;
}

.inline-date-picker .react-datepicker__month-select,
.inline-date-picker .react-datepicker__year-select {
  font-size: 1.2rem;
  border: solid 1px #AAA;
  border-radius: 3px;
}

.inline-date-picker .react-datepicker__day-name {
  font-weight: bold;
}

.inline-date-picker .react-datepicker__day {
  font-size: 1.1rem;
  font-weight: 500;
}