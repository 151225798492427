@import "../../common/properties.css";

.slide-practice-card-images {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  border: #000 solid 2px;
  height: 409px;
  width: 724px;
}

.dom-slide {
  display: block;
  margin: 0;
  padding: 20px;
  width: 720px;
  height: 405px;
}

.dom-slide-area {
  margin: 0;
  padding: 0;
  text-align: center;
  height: 100%;
  width: 100%;
}

.dom-slide-title {
  font-size: 2.5rem;
  margin: 10px 0 20px;
}

.dom-slide-title.two-rows {
  font-size: 2.3rem;
  margin: 0;
}

.dom-slide-content {
  width: 100%;
  height: 300px;
}

.dom-slide-answerarea {
  /* flex親要素としての指定 */
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-around;
  height: fit-content;
}

div.item-group {
  margin-top: 15px;
  width: 125px;

  /* flex親要素としての指定 */
  display: flex;
  flex-flow: column;
  flex-wrap: no-wrap;
  justify-content: flex-start;
  align-items: center;
}

div.item-group.two-rows {
  margin-top: 8px;
}

div.item-group img {
  display: block;
  width: 90px;
  margin-bottom: 5px;
}

div.item-group.two-rows img {
  width: 65px;
}

label.item-label {
  display: block;
  width: 90px;
  margin: 0 0 7px;
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
}

label.item-label.two-rows {
  width: 65px;
  margin: 0;
  font-size: 1.6rem;
}

span.input-item,
input[type="text"].input-item {
  display: block;
  width: 90px;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  background-color: #FFF;
  border: none;
  border-radius: 6px;
  padding: 8px 0;
  line-height: 1.6rem;
  height: 35px;
}

input[type="text"].input-item {
  border: 1px solid #BBB;
}

select.input-item {
  display: block;
  width: 90px;
  font-size: 1.4rem;
  text-align: center;
  background-color: #FFF;
  border: 1px solid #999;
  border-radius: 3px;
  padding: 6px 5px 8px 3px;
  line-height: 1.6rem;
}

select.input-item.two-rows {
  width: 65px;
  padding: 0;
}

span.input-item.two-rows {
  width: 65px;
  padding: 5px 0 0;
  height: 25px;
}

span.input-item.correct {
  background-color: var(--color-correct);
}

span.input-item.incorrect {
  background-color: var(--color-incorrect);
}

span.input-item.result.correct-answer {
  background-color: inherit;
  width: 90px;
}

.button-area {
  margin-top: 25px;
}

.button-area.two-rows {
  margin-top: 10px;
}

.button {
  margin-right: 0;
  width: 280px;
}
