@import "../../common/properties.css";

.inner-link {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  color: var(--color-main);
  text-decoration: none;
  transition: 0.3s;
}

.inner-link:hover {
  color: var(--color-sub);
}
