@charset "UTF-8";

.react-tabs__tab {
    width: 40%;
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    color: #333;
    text-align: center;
}

.react-tabs__tab:first-child {
    margin-left: 9%;
}

.react-tabs__tab--selected {
    background-color: var(--color-pale);
    border-color: #AAA;
    font-weight: bold;
    border-radius: 5px 5px 0 0;
}

.react-tabs__tab:focus:after {
    content: none;
}

