@import "../../common/properties.css";

.lesson-template {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  box-sizing: border-box;
}

.slider-area {
  background-color: #FFF;
  padding: 2rem 0 5rem;
}

.slide-all-wrap {
  margin: 0 auto;
  width: fit-content;
}

.slide-title {
  font-size: 1.8rem;
  margin-bottom: 0;
}

.course-title {
  margin-top: 0;
  font-size: 1.5rem;
  font-weight: bold;
}

.course-title span {
  padding: 0 1em 0.1em;
  color: #FFF;
  font-size: 1.3rem;
  border-radius: 5px;
  margin-right: 1em;
  font-weight: 500;
}

.slide-wrap {
  text-align: center;
}

.slide-content {}

.slide-control {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  position: relative;
}

.slide-pager {
  display: flex;
  align-items: center;
}

.slide-page-number {
  font-size: 1.8rem;
  color: #666;
  padding: 0 1em;
}

.slide-control .btn-before,
.slide-control .btn-next {
  display: inline-block;
  font-size: 1.8rem;
}

.slide-control .btn-before img,
.slide-control .btn-next img {
  vertical-align: middle;
}

.slide-control .btn-before {
  transform: rotate(180deg);
}

.slide-control .btn-fullscreen {
  position: absolute;
  right: 0;
  top: 0;
}

.slide-lesson-link {
  text-align: center;
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.slide-lesson-link .btn-wrap {
  margin: 2%;
}

.slide-lesson-link .button {
  color: var(--color-main);
}

.slide-lesson-link .btn-index {
  width: 100%;
}

/*** レッスンへのリンク ***/
.button {
  display: inline-block;
  padding: 0.8em 1em;
  color: #555;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background-color: #FFF;
  border-radius: 4px;
  border: 1px solid #BBB;
  cursor: pointer;
  box-sizing: border-box;
}

.button:hover {
  color: #333;
  border-color: #888;
  outline: 0;
}

.innerlink-disabled {
  opacity: 0.6;
  pointer-events: none;
}

/****** color ******/

.color-yellow {
  color: var(--color-yellow);
}

.color-red {
  color: var(--color-red);
}

.color-blue {
  color: var(--color-blue);
}

.bg-color-yellow {
  background-color: var(--color-yellow);
}

.bg-color-red {
  background-color: var(--color-red);
}

.bg-color-blue {
  background-color: var(--color-blue);
}

@media (min-width: 550px) {
  .slide-lesson-link .button {
    width: 200px;
  }

  .slide-lesson-link .btn-index {
    width: auto;
    order: 2;
  }

  .slide-lesson-link .btn-before-lesson {
    order: 1;
  }

  .slide-lesson-link .btn-next-lesson {
    order: 3;
  }
}

/* Larger than Desktop HD */
@media (min-width: 750px) {
  .slide-title-wrap {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }

  .slide-title {
    font-size: 3.2rem;
    margin-right: 1em;
  }

  .slide-page {
    font-size: 2.1rem;
  }

  .slide-lesson-link {
    margin-top: 5rem;
  }
}
