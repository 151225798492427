@import "../../common/properties.css";

.page-title-area {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  background: url("../../../assets/image/png/tit_ptn.png") center center repeat;
  background-size: 70px;
  padding: 5px 0;
}

.page-title {
  color: var(--color-main);
  font-size: 2.4rem;
}

/* Larger than tablet */
@media (min-width: 750px) {
  .page-title-area {
    background-size: 88px;
  }

  .page-title {
    font-size: 3.6rem;
  }
}
