@import "../../common/properties.css";

.my-page-template {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
}

.mypage-top-area {
  padding: 3rem 0 4rem;
}

.pao-message {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.pao-message-text {
  background-color: var(--color-light-red);
  margin: 0;
  padding: 0.5em 1.2em;
  border-radius: 2em;
  margin-right: 5%;
  margin-bottom: 1em;
  position: relative;
  font-size: 1.4rem;
}

.pao-message-text::after {
  content: "";
  position: absolute;
  top: 0.5em;
  right: -0.8em;
  bottom: 0;
  width: 0;
  height: 0;
  margin: auto;
  border-style: solid;
  border-color: transparent transparent var(--color-light-red) var(--color-light-red);
  border-width: 0.5em;
}

.pao-illust {
  width: 25%;
  max-width: 100px;
  min-width: 70px;
  align-self: stretch;
  display: flex;
  align-items: flex-end;
}

.box-main {
  background-color: #FFF;
  box-shadow: 0 0 6px #0000000F;
}

.user-info-area {
  padding: 30px;
}

.user-profile {
  text-align: center;
  padding-bottom: 2rem;
  border-bottom: 1px solid #CCC;
}

.profile-box {
  margin-bottom: 20px;
}

.profile-photo {
  width: 123px;
  margin: 0 auto;
}

.profile-photo img {
  border-radius: 50%;
}

.user-name {
  font-size: 3.6rem;
  line-height: 1;
  color: var(--color-main);
  margin-bottom: 0.1em;
}

.user-level {
  font-size: 2.4rem;
  color: #999;
  margin: 15px 0 0;
  line-height: 1;
}

.user-point {
  font-size: 2rem;
  color: #999;
  margin: 10px 0 15px;
  line-height: 1;
}

.registration-date {
  margin: 0;
  color: #999;
  font-size: 1.4rem;
}

.text-center {
  text-align: center;
}

.attendance-list {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.attendance-list dl {
  margin: 0 3% 2rem;
}

.attendance-list dl:last-child {
  margin-right: 0;
}

.attendance-list dt {
  padding: 0.3em 1.5em 0.4em;
  background-color: #EDE393;
  font-size: 1.2rem;
  border-radius: 20px;
  font-weight: 500;
  text-align: center;
}

.attendance-list dd {
  font-size: 5.2rem;
  color: var(--color-main);
  margin: 0;
  text-align: center;
}

.fraction {
  display: flex;
  justify-content: center;
  font-size: 5.2rem;
  color: var(--color-main);
  margin: 0;
  text-align: center;
}

.fraction span.denominator {
  color: #999;
  font-size: 3.4rem;
  position: relative;
  padding-top: 4rem;
  padding-left: 3rem;
}

.fraction .unit {
  padding-left: 0.1em;
  font-size: 80%;
}

.fraction > span.denominator::before {
  content: "";
  display: block;
  width: 50px;
  height: 1px;
  background-color: #707070;
  transform: rotate(-65deg);
  position: absolute;
  left: -8%;
  bottom: 40%;
}

.days {
  color: #999;
  padding-top: 4rem;
  margin-top: 2rem;
  font-size: 1.8rem;
  font-weight: bold;
  margin-left: 0.4em;
}

p.message {
  font-size: 1.6rem;
  background-color: white;
  border: 3px solid #888;
  padding: 10px 20px;
}

.training-content {
  margin-bottom: 30px;
}

.training-box {
  margin-bottom: 2rem;
}

.training-bnr {
  background: url("../../../assets/image/png/blue_ptn.png") center center repeat;
  background-size: 90px auto;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  color: #FFF;
  height: 100%;
  padding: 4%;
}

.training-bnr:hover {
  color: #FFF;
}

.training-bnr.disabled {
  opacity: 0.5;
}

.training-bnr-icon {
  width: 28%;
  max-width: 90px;
}

.training-bnr-text {
  width: 65%;
  text-align: center;
  font-size: 2rem;
  line-height: 1.4;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-training {
  box-sizing: border-box;
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {
  .user-profile {
    text-align: left;
  }

  .profile-box {
    display: flex;
    justify-content: center;
  }

  .profile-photo {
    margin: 0 10% 0 0;
  }
}

/* Larger than Desktop HD */
@media (min-width: 750px) {
  .mypage-top-area {
    padding: 4rem 0 6rem;
  }

  .pao-message {
    justify-content: flex-end;
    align-items: center;
  }

  .pao-message-text {
    margin-right: 2em;
    font-size: 1.6rem;
  }

  .training-box {
    width: 48%;
  }

  .training-content {
    text-align: center;
  }

  .row-training {
    display: flex;
    justify-content: space-between;
  }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
  .user-info-area {
    display: flex;
  }

  .user-profile {
    border-bottom: none;
    border-right: 1px solid #CCC;
    width: 70%;
    padding-bottom: 0;
  }

  .attendance-list {
    width: 30%;
    padding-left: 3%;
    justify-content: space-around;
  }

  /* .attendance-list dl {} */

  .attendance-list dt {
    font-size: 1.4rem;
  }
}
