@import "../../common/properties.css";

.signup-template {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  background-color: var(--pale-color);
  text-align: center;
}

.page-signup-logo {
  margin: 5rem auto;
  width: 200px;
}

.signup-title {
  font-weight: 700;
  font-size: 1.8rem;
  margin: 1em;
  color: var(--color-main);
}

.button {
  width: 100%;
}

.form-component-wrap {
  text-align: left;
}

.signup-check-area {
  margin-top: 2rem;
}

.signup-check {
  display: block;
}

.signup-label-body {
  display: inline-block;
  margin-left: 0.5rem;
  font-weight: normal;
}

.form-component-wrap:not(:first-child),
.button-wrap:not(:first-child) {
  margin-top: 2rem;
}

.form-component-wrap input {
  width: 100%;
}

.form-component-wrap .password-box {
  width: 100%;
}

.form-component-wrap .password-box input {
  width: calc(100% - 29px - 1rem);
}

.form-component-wrap label {
  width: 9em;
  flex-shrink: 0;
}

.forget-pw-link {
  text-align: right;

  /* font-size: 1.3rem; */
  font-size: 0.8rem;
}

.box-main {
  background-color: #FFF;
  box-shadow: 0 0 6px #0000000F;
}

.signup-other {
  margin-top: 4rem;
  position: relative;
}

.signup-other-txt {
  display: inline-block;
  padding: 0 1em;
  background-color: #FFF;
  position: relative;
}

.signup-other::before {
  content: "";
  display: block;
  width: 100%;
  height: 0;
  border-top: 1px solid #EEE;
  position: absolute;
  left: 0;
  top: 0.9em;
}

.signup-other-item {
  margin-top: 2rem;
}

.signup-other-item img {
  vertical-align: sub;
  margin-right: 1rem;
}

/* Larger than mobile */
@media (min-width: 400px) {}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {}

/* Larger than tablet */
@media (min-width: 750px) {
  .form-component-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button,
  .form-component-wrap input,
  .form-component-wrap .password-box {
    width: 280px;
  }
}
