@import "../../common/properties.css";

.appendix-template {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
}

.appendix-area {
  text-align: center;
}

.explanation-area {
  margin-bottom: 50px;
}

.download-area {
  margin-bottom: 30px;
}

.button {
  height: 40px;
  width: 100%;
  max-width: 500px;
  min-width: 250px;
}

.button img {
  width: 18px;
  height: 19px;
}

/* Larger than tablet */
@media (min-width: 750px) {
  .button {
    width: 60%;
  }
}
