@import "../../common/properties.css";

.breadcrumb-bar {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  padding: 10px 0;
  font-size: 1.3rem;
}

.breadcrumb-list {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.breadcrumb-item {
  margin: 0;
}

.breadcrumb-item a {
  color: #333;
}

.breadcrumb-item a:hover {
  color: var(--color-sub);
}

.breadcrumb-item a::after {
  content: "";
  background: url("../../../assets/image/svg/icon_trg02.svg") center center no-repeat;
  width: 1em;
  padding: 1em;
  vertical-align: middle;
}

@media (min-width: 750px) {
}
