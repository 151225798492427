@import "../../common/properties.css";

.message-bar {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  padding: 1rem 2rem;
  border-radius: 6px;
  margin: 1rem;
}

.success {
  color: var(--color-message-success);
  background-color: var(--color-message-success-bk);
}

.error {
  color: var(--color-message-error);
  background-color: var(--color-message-error-bk);
}
