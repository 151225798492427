@import "../../common/properties.css";

.doorway-layout {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  background-color: var(--color-pale);
  text-align: center;
  margin: 0;
}

div .doorway-layout {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  background-color: var(--color-pale);
  text-align: center;
  margin: 0;
}

.doorway-logo {
  margin: 0 auto;
  padding: 5rem 0;
  width: 200px;
}

.doorway-title {
  font-weight: 700;
  font-size: 1.8rem;
  margin: 1em;
  color: var(--color-main);
}

.doorway-error-message {
  margin: 0 auto;
  max-width: 540px;
}
