@import "../../common/properties.css";

.footer {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  padding: 3rem 5%;
  margin-top: 6rem;
  color: #FFF;
  background: var(--color-main);
  text-align: center;
}

.footer a {
  color: #FFF;
  text-decoration: none;
}

.footer-nav-list {
  list-style: none;
}

.footer-nav-item {
  margin-top: 1rem;
}

.footer-nav-item a {
  padding: 0 0.5rem;
}

a.pseudo-inner-link:hover {
  color: var(--color-sub);
}

.footer-logo {
  width: 180px;
  margin: 6rem auto 0;
  text-align: center;
}

.copyright-area {
  margin-top: 3rem;
  font-size: small;
}

/* Larger than tablet */
@media (min-width: 750px) {
  .footer {
    padding: 4rem 5%;

    /* margin-top: 8rem; */
  }

  .footer-nav-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .footer-nav-item a {
    padding: 0 1em;
  }

  .footer-logo {
    width: 200px;
  }
}
