@import "../../common/properties.css";

.lesson-box {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  box-sizing: border-box;
  background-color: #FFF;
  box-shadow: 0 0 6px #E5E5E5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5%;
  margin-bottom: 2.5rem;
}

.lesson-box:hover {
  opacity: 0.8;
}

.lesson-box.unreadable {
  opacity: 0.6;
}

.lesson-text-box {
  display: flex;
  align-items: center;
  color: #333;
  width: 100%;
}

.lesson-number {
  font-size: 3.2rem;
  line-height: 1;
  margin: 0;
  text-align: center;
}

.lesson-number span {
  font-size: 1.2rem;
  display: block;
  width: 55px;
}

.lesson-text {
  border-left: 1px solid #CED4D8;
  padding: 0 4%;
  margin-left: 4%;
  width: 93%;
}

.lesson-title {
  display: block;
  width: 100%;
  margin: 0;
  color: #333;
  font-size: 1.5rem;
}

.lesson-page-number {
  display: block;
  font-size: 1.2rem;
  color: #999;
}

.lesson-detail {
  font-size: 1.4rem;
  color: #999;
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}

.d-none {
  display: none;
}

/****** color ******/
.color-yellow {
  color: var(--color-yellow);
}

.color-red {
  color: var(--color-red);
}

.color-sub-red {
  color: var(--color-sub-red);
}

.color-blue {
  color: var(--color-blue);
}

/* Larger than tablet */
@media (min-width: 550px) {
  .d-sm-block {
    display: block;
  }
}

/* Larger than tablet */
@media (min-width: 750px) {
  .lesson-box {
    padding: 2.5rem;
  }

  .lesson-number {
    font-size: 3.9rem;
  }

  .lesson-title {
    font-size: 1.8rem;
  }

  .lesson-page-number {
    display: inline;
    margin-left: 0.6em;
    font-size: 1.4rem;
  }

  .d-md-block {
    display: block;
  }
}
