@import "../../common/properties.css";

.profile-template {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
}

.form-area {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  padding: 2rem 7.5% 5rem;
  background-color: #FFF;
}

.explanation-text {
  text-align: center;
}

.form-item {
  margin-top: 5rem;
}

.form-item-label {
  font-weight: 500;
  margin-bottom: 15px;
}

.form-item input[type="password"],
.form-item input[type="text"],
.form-item input[type="email"],
.form-item textarea {
  width: 100%;
}

.form-item input:nth-of-type(2) {
  margin: 20px 0;
}

.form-item .pulldown {
  margin-right: 10px;
}

.form-item textarea {
  width: 100%;
  height: 200px;
  resize: none;
}

.form-item .password-box {
  width: 100%;
}

.form-item .password-box input {
  width: calc(100% - 29px - 1rem);
}

.button {
  width: 100%;
}

@media (min-width: 750px) {
  .form-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 0;
  }

  .form-item .password-box,
  .form-item input {
    max-width: 400px;
  }

  .align-start {
    align-items: flex-start;
  }

  .form-item-label {
    width: 100%;
    max-width: 180px;
    margin-bottom: 0;
    letter-spacing: 0.05em;
  }

  .form-item-textarea {
    width: 60%;
    max-width: 600px;
    height: 200px;
  }

  .form-item-select {
    width: 100%;
  }

  .form-item-select select {
    margin-right: 1%;
  }

  .release {
    font-size: 1.4rem;
  }

  .button {
    width: 280px;
  }
}
