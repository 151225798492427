@import "../../common/properties.css";

.news-template {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
}

.news-area {
  background-color: rgba(27 151 228 / 7%);
  border-radius: 10px;
  margin-bottom: 4rem;
}

.news-body {
  width: 90%;
  margin: 0 auto;
  padding-top: 20px;
}

.news-wrap {
  padding: 0 5%;
}
