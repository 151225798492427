@import "../../common/properties.css";

.chained-slides {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  box-sizing: border-box;
  width: fit-content;
  height: fit-content;
}

.slide-control {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  position: relative;
}

.slide-pager {
  display: flex;
  align-items: center;
}

.slide-page-number {
  font-size: 1.8rem;
  color: #666;
  padding: 0 1em;
}

.slide-control .btn-before,
.slide-control .btn-next {
  display: inline-block;
  font-size: 1.8rem;
}

.slide-control .btn-before img,
.slide-control .btn-next img {
  vertical-align: middle;
}

.slide-control .btn-before {
  transform: rotate(180deg);
}

.slide-control .btn-before.dead-end,
.slide-control .btn-next.dead-end {
  opacity: 0.6;
  cursor: not-allowed;
}

.slide-control .btn-fullscreen {
  /* display: none; */
  position: absolute;
  right: 0;
  top: 0;
}
