@import "../../common/properties.css";

.contract-article {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
}

.contract-article-title {
  font-size: 1.8rem;
  font-weight: 700;
  padding-bottom: 0.3em;
  margin: 2.5em 0 1.2em;
  border-bottom: 3px solid var(--color-main);
  color: var(--color-main);
  letter-spacing: 0.06em;
  overflow: hidden;
}

.contract-article-title:first-child {
  margin-top: 0;
}

.contract-article-content ul li {
  list-style-type: disc;
}

@media (min-width: 750px) {
  .contract-article-title {
    font-size: 2.4rem;
  }
}
