@import "../../common/properties.css";

.training-template {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
}

.footer {
  margin-top: 0;
}

.color-blue {
  color: var(--color-blue);
}

.color-red {
  color: var(--color-red);
}

.training-wrap {
  padding: 4rem 0;
  background-color: #FFF;
}

.training-content {
  margin-bottom: 0 auto 30px;
  max-width: 750px;
  text-align: center;
}

.training-header {
  font-size: 2.4rem;
  margin-bottom: 1em;
}

.training-waitingtime {
  color: var(--color-main);
  font-size: 6rem;
  margin-bottom: 1em;
}

.training-comment {
  font-size: 1.8rem;
  margin-bottom: 0.5em;
}

/* 残り時間表示欄 */
.time-list-group {
  margin: 3rem 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.time-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 1rem;
}

.time-list dt {
  padding: 0.3em 1.5em 0.4em;
  background-color: #EDE393;
  font-size: 1.2rem;
  border-radius: 20px;
  font-weight: 500;
  margin-right: 1em;
}

.time-list dd {
  font-size: 2.8rem;
  color: var(--color-main);
  margin: 0 -5px;
  text-align: center;
}

.check-header {
  font-size: 3rem;
  margin-bottom: 1em;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.check-ttl {
  padding-left: 0.5em;
}

.training-finish-paozo {
  max-width: 200px;
  margin: 2rem auto;
}

.fraction {
  display: flex;
  justify-content: center;
  font-size: 5.2rem;
  color: var(--color-main);
  margin: 0;
  text-align: center;
}

.fraction span {
  color: #999;
  font-size: 3.4rem;
  position: relative;
  padding-top: 4rem;
  padding-left: 3rem;
}

.fraction .unit {
  padding-left: 0.1em;
  font-size: 80%;
}

.fraction > span::before {
  content: "";
  display: block;
  width: 50px;
  height: 1px;
  background-color: #707070;
  transform: rotate(-65deg);
  position: absolute;
  left: -8%;
  bottom: 40%;
}

.button {
  width: 100%;
}

@media (min-width: 1000px) {
  .training-wrap {
    padding: 7rem 0;
  }

  .training-header {
    font-size: 4rem;
  }

  .training-comment {
    font-size: 2.4rem;
  }

  .training-waitingtime {
    font-size: 8rem;
  }

  .button {
    width: 280px;
  }
}
