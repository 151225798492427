@import "../../common/properties.css";

.badge {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  border-radius: 5px;
  margin-left: 0.5em;
  padding: 0.1em 0.5em 0.2em;
  color: #FFF;
  font-size: 1.2rem;
}

/* 公開 */
.open {
  background-color: var(--color-sub-red);
}

/* 非公開 */
.close {
  background-color: var(--color-gray);
}

/* 必須項目 */
.required-astarisk {
  font-weight: bold;
  color: var(--color-red);
  padding: 0;
}

@media (min-width: 750px) {
  .badge {
    font-size: 1.4rem;
  }
}
