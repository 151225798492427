@import "../../common/properties.css";

.training-area {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  max-width: 750px;
}

.training-input-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 3rem 0 1rem;
}

.training-input-group {
  display: flex;
  justify-content: center;
  margin: 1.8rem;
}

.flex-flow-column {
  flex-flow: column;
}

.btn-before,
.btn-next {
  display: inline-block;
  font-size: 1.8rem;
}

.btn-before img,
.btn-next img {
  vertical-align: middle;
}

.btn-before {
  transform: rotate(180deg);
}

.btn-before.dead-end,
.btn-next.dead-end {
  opacity: 0.6;
  cursor: not-allowed;
}

.training-input-group.alphabet {
  margin-left: 4rem;
  margin-right: 4rem;
}

.training-input-group.number {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  justify-content: center;
}

.training-input-group .inner-group {
  display: flex;
  flex-flow: row;
  margin: 0;
  padding: 0;
}

.training-input-item {
  margin: 0 0.5rem;
}

.training-input-item.word {
  margin: 0;
}

.training-input-item span,
.training-input-item input[type="text"] {
  display: block;
  padding: 0.3em;
  font-size: 2.4rem;
  font-weight: 700;
  width: 1.6em;
  height: 2.2em;
  text-align: center;
  line-height: 1.6em;
  background-color: #EEE;
  border: 1px solid #BBB;
  border-radius: 6px;
}

.training-input-item span {
  border: none;
}

.training-input-item.word span,
.training-input-item.word input[type="text"] {
  padding: 0.9rem 0 0.1rem;
  font-size: 1.4rem;
  height: 39.4px;
  min-width: 110px;
}

.training-input-item.alphabet span,
.training-input-item.alphabet input[type="text"] {
  font-family: var(--font-noto-serif);
}

.training-input-item.alphabet input[type="text"] {
  padding: 0;
}

.training-input-item.name-and-face span,
.training-input-item.name-and-face input[type="text"] {
  font-size: 1.8rem;
  min-width: 5em;
  width: 100%;
}

.training-input-item.name-and-face {
  background-color: transparent;
  max-width: 200px;
}

.training-input-item.national-capitals span {
  font-size: 1.8rem;
  padding-bottom: 4rem;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.training-input-item.national-capitals input[type="text"] {
  font-size: 1.8rem;
  padding: 0;
  width: 100%;
}

.training-input-item.national-capitals {
  background-color: transparent;
  width: 200px;
}

.training-face-photo {
  margin-bottom: 1rem;
}

.training-input-item span.correct {
  background-color: var(--color-correct);
}

.training-input-item span.incorrect {
  background-color: var(--color-incorrect);

  /* background-color: #ED5566; */
}

.training-input-item span.correct-answer {
  background-color: transparent;
}

/* 円周率用に定義 */

.group-for-circle-ratio-3 {
  padding-left: 10%;
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
  width: 100%;
  display: flex;
}

.item-for-circle-ratio-3 {
  display: block;
  padding: 0.3em;
  font-size: 2.4rem;
  font-weight: 700;
  width: 1.6em;
  height: 2.2em;
  text-align: center;
  line-height: 1.6em;
  margin: 0 0.5rem;
}

.training-input-group .circle-ratio-digits-group {
  display: flex;
  flex-flow: row;
  margin: 0;
  padding: 0;
  justify-content: center;
}

.circle-ratio-digits {
  border: 0;
  justify-content: center;
  display: flex;
}

.circle-ratio-digits-and-item {
  justify-content: center;
}

.circle-ratio-digits span {
  display: block;
  font-size: 1.2rem;
  width: 3em;
  height: 2em;
  text-align: center;
}

/* ページング */

.paging-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 3rem;
}

.paging-group {
  margin: 0.5rem;
  padding: 0.7rem 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 1px solid #FFF;
  border-radius: 15px;
  background-color: #CDEEF8;
}

.paging-item {
  margin: 0 2rem;
}

.paging-item span {
  display: block;
  padding: 0.2em 0.7em;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  background-color: #FFF;
  border: 1px solid #000;
  border-radius: 6px;
}

/* @media (min-width: 1000px) {
  .training-input-item span,
  .training-input-item input[type="text"] {
    font-size: 2.8rem;
  }
}

@media (min-width: 1200px) {
  .training-input-item span,
  .training-input-item input[type="text"] {
    font-size: 3.2rem;
  }
} */
