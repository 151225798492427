@import "../../common/properties.css";

.column-layout {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  display: flex;
  align-items: baseline;
  flex-direction: column;
}

.reverse {
  flex-direction: column-reverse;
}

/* Larger than desktop */
@media (min-width: 1000px) {
  .column-layout {
    display: flex;
    flex-direction: row;
  }
}
