@import "../../common/properties.css";

.training-title-bar {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  color: #FFF;
  margin: 8rem 0 5rem;
  padding: 4rem 4% 4%;
  border-radius: 2rem;
  display: block;
  position: relative;
  text-align: center;
  background: url("../../../assets/image/png/blue_ptn.png") center center repeat;
  background-size: 90px auto;
  justify-content: center;
  height: 100%;
}

.training-title {
  font-size: 2em;
  margin: 0.67em 0;
}

.training-title-icon {
  position: absolute;
  top: -4.7rem;
  left: calc(50% - 4.7rem);
  width: 9.4rem;
}

@media (min-width: 1000px) {
  .training-title-bar {
    margin: 2rem 0 5rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
    text-align: left;
    padding: 1rem 4rem 1rem 18rem;
  }

  .training-title {
    margin: 0;
  }

  .training-title-icon {
    top: calc(50% - 5rem);
    left: 4rem;
    width: 10rem;
  }

  .training-title-comment {
    margin: 0;
  }
}
