@import "../../common/properties.css";

.news-list {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  border-bottom: 1px solid #CED4D8;
  padding-bottom: 20px;
}

.news-list dt {
  color: #999;
  font-weight: 500;
}

.news-list dd {
  color: #333;
  margin-left: 0;
}
