@import "../../common/properties.css";

.doorway-form-layout {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
}

.doorway-form-container {
  max-width: 540px;
  margin: 5rem auto 3rem;
  padding: 5rem 4%;
  background-color: #FFF;
  box-shadow: 0 0 6px #0000000F;
}

.doorway-form-title {
  font-weight: 700;
  font-size: 1.8rem;
  margin: 1em;
  color: var(--color-main);
}
