@import "../../common/properties.css";

.slide-practice-sentence {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  border: #000 solid 2px;
  height: 409px;
  width: 724px;
}

.dom-slide {
  display: block;
  margin: 0;
  padding: 20px 40px;
  width: 720px;
  height: 405px;
}

.dom-slide-area {
  margin: 0;
  padding: 0;
  text-align: center;
  height: 100%;
  width: 100%;
}

.dom-slide-title {
  font-size: 2.2rem;
  margin: 10px 0;
}

.dom-slide-content {
  width: 100%;
  height: 300px;
}

.dom-slide-answerarea {
  /* flex親要素としての指定 */
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 260px;
}

div.item-group {
  width: 550px;
  height: 32px;
  margin: 0 30px 5px;

  /* margin-right: 15px; */

  /* flex親要素としての指定 */
  display: flex;
  flex-flow: row;
  flex-wrap: no-wrap;
  justify-content: flex-start;
}

span.item-label {
  display: block;
  width: 30px;
  height: 32px;
  font-size: 1.8rem;
  font-weight: 700;
}

span.input-item,
input[type="text"].input-item {
  display: block;
  width: 500px;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: left;
  background-color: #FFF;
  border: none;
  border-radius: 6px;
  padding: 5px 0 0 30px;
  line-height: 2rem;
  height: 32px;
  margin: 0 0 0 10px;
}

span.sentence.result {
  margin: 0 0 0 10px;
  width: 400px;
}

input[type="text"].input-item {
  border: 1px solid #BBB;
  padding: 0 0 0 30px;
}

span.input-item.result {
  width: 90px;
}

span.input-item.correct {
  background-color: var(--color-correct);
}

span.input-item.incorrect {
  background-color: var(--color-incorrect);
}

.button-area {
  margin-top: 5px;
}

.button {
  margin-right: 0;
  width: 280px;
}
