@import "../../common/properties.css";

.news-area {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  background-color: rgba(27 151 228 / 7%);
  border-radius: 6px;
  margin-bottom: 4rem;
}

.news-title-box {
  background-color: #1A95E3;
  padding: 10px;
  position: relative;
  border-radius: 6px;
}

.news-title-box::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -15px;
  left: 0;
  width: 0;
  height: 0;
  margin: auto;
  border-style: solid;
  border-color: #1A95E3 transparent;
  border-width: 15px 15px 0;
}

.news-title-inner {
  background-color: var(--color-main);
  text-align: center;
  padding: 1.5rem;
  border-radius: 6px;
}

.news-title {
  font-size: 2.2rem;
  margin: 0 0 10px;
  color: #FFF;
  line-height: 1;
  letter-spacing: 0;
}

.news-title span {
  white-space: nowrap;
}

.side-title {
  margin-top: 2em;
  margin-bottom: 1em;
}

.news-body {
  width: 85%;
  margin: 0 auto;
}
