@import "../../common/properties.css";

.container {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.page-content-area {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

@media (min-width: 400px) {
  .container {
    width: 85%;
    padding: 0;
  }
}

/* Larger than tablet */
@media (min-width: 750px) {
  .page-content-area {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
}

@media (min-width: 1000px) {
  .container {
    width: 80%;
  }
}
