@import "../../common/properties.css";

.course-box {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
  margin-bottom: 30px;
}

.title-bar {
  margin-top: 2.5em;
  margin-bottom: 1.5em;
  padding: 0.5em 1em;
  font-size: 1.8rem;
  color: #FFF;
  border-radius: 6px;
  letter-spacing: 0.06em;
  position: relative;
}

.title-bar.accordion-title {
  padding-right: 3em;
}

.accordion-title {
  cursor: pointer;
}

.accordion-title::after {
  content: "";
  display: block;
  width: 18px;
  height: 9px;
  background: url("../../../assets/image/svg/icon_trg04.svg") no-repeat center center;
  position: absolute;
  right: 1em;
  transform: rotate(180deg);
  top: 45%;
  transition: 0.4s;
}

.accordion-title.open::after {
  transform: none;
}

.accordion-content {
  display: none;
}

/****** background-color ******/
.bg-color-yellow {
  background-color: var(--color-yellow);
}

.bg-color-red {
  background-color: var(--color-red);
}

.bg-color-blue {
  background-color: var(--color-blue);
}

@media (min-width: 1000px) {
  .course-box {
    padding-top: 2rem;
  }

  .course-box:first-of-type {
    margin-top: -2rem;
  }

  .title-bar:first-of-type {
    margin-top: 0;
  }
}
