@import "../../common/properties.css";

.change-password-template {
  font-family: var(--font-noto-sans-jp), "Hiragino Kaku Gothic ProN", Meiryo, verdana, sans-serif;
}

.form-area {
  margin: 0;
  width: 100%;
  padding: 2rem 7.5%;
  background-color: #FFF;
}

.explanation-text {
  text-align: center;
}

.notation-text {
  margin: 5px 0 0;
}

.form-item {
  margin-top: 2rem;
}

.form-item-label {
  font-weight: 500;
  margin-bottom: 15px;
}

.form-item input[type="password"],
.form-item input[type="text"],
.form-item input[type="email"],
.form-item textarea {
  width: 100%;
}

.form-item input:nth-of-type(2) {
  margin: 20px 0;
}

.form-item .password-box {
  display: block;
  width: 100%;
}

.form-item .password-box input {
  width: calc(100% - 29px - 1rem);
}

.button {
  margin-top: 30px;
  width: 100%;
}

@media (min-width: 750px) {
  .form-area {
    margin: 0 auto;
    width: 80%;
    max-width: 850px;
    padding: 2rem 70px 3rem;
  }

  .notation-text {
    margin: 5px 0 0 100px;
  }

  .form-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 0;
  }

  .form-item .password-box,
  .form-item input {
    max-width: 400px;
  }

  .form-item-label {
    width: 100%;
    max-width: 180px;
    margin-bottom: 0;
    letter-spacing: 0.05em;
  }

  .button {
    width: 280px;
  }
}
